<template><v-app><Header /><v-main><v-container fluid>
<v-row dense><v-col cols="12"><v-card>
    <v-card-title class="text-h5 green text--info white--text">{{ $t('Profile saya') }}</v-card-title>
    <v-card-subtitle class="green white--text">{{ $t('Profile saya sendiri') }}</v-card-subtitle>
    <v-card-text class="text--primary mt-2">
        
    <v-row>
        <v-col cols="12" md="3">
            <v-row class="justify-center"><v-col cols="12">
                <v-card>
                    <v-card-text class="text--primary">
                        <v-img :lazy-src="nophoto" :src="datatables.photoUrl" max></v-img>
                    </v-card-text>
                </v-card>
            </v-col></v-row>
                
            <v-row><v-col cols="12" class="text-center">
                <barcode :value="datatables.memberno" font-options="bold" text=" " format="CODE39">
                    Can't generate the barcode
                </barcode>
            </v-col></v-row>
        </v-col>
    
        <v-col cols="12" md="9">
            <v-row><v-col cols="12">
                <v-card>
                    <v-card-text class="text--primary">
                        <v-row>
                            <v-col cols="6" md="3">{{ $t('No. Anggota') }}</v-col>
                            <v-col cols="6" md="3">{{ datatables.memberno }}</v-col>
                            <v-col cols="6" md="3">{{ $t('Nama lengkap') }}</v-col>
                            <v-col cols="6" md="3">{{ datatables.fullname }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="3">{{ $t('Tempat lahir') }}</v-col>
                            <v-col cols="6" md="3">{{ datatables.placeOfBirth }}</v-col>
                            <v-col cols="6" md="3">{{ $t('Tanggal lahir') }}</v-col>
                            <v-col cols="6" md="3">{{ datatables.dateOfBirth_show }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="3">{{ $t('Alamat') }}</v-col>
                            <v-col cols="6" md="3">{{ datatables.address }}</v-col>
                            <v-col cols="6" md="3">{{ $t('Kota tinggal') }}</v-col>
                            <v-col cols="6" md="3">{{ datatables.city }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="3">{{ $t('Provinsi tinggal') }}</v-col>
                            <v-col cols="6" md="3">{{ datatables.province }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="3">{{ $t('Nomor hp') }}</v-col>
                            <v-col cols="6" md="3">{{ datatables.noHp }}</v-col>
                            <v-col cols="6" md="3">{{ $t('Nomor telepon') }}</v-col>
                            <v-col cols="6" md="3">{{ datatables.phone }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="3">{{ $t('Jenis identitas') }}</v-col>
                            <v-col cols="6" md="3">{{ datatables.identityType }}</v-col>
                            <v-col cols="6" md="3">{{ $t('Nomor identitas') }}</v-col>
                            <v-col cols="6" md="3">{{ datatables.identityNo }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="3">{{ $t('Jenis kelamin') }}</v-col>
                            <v-col cols="6" md="3">{{ datatables.jenis_kelamin }}</v-col>
                            <v-col cols="6" md="3">{{ $t('Pekerjaan') }}</v-col>
                            <v-col cols="6" md="3">{{ datatables.job }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="3">{{ $t('Agama') }}</v-col>
                            <v-col cols="6" md="3">{{ datatables.agama }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="3">{{ $t('Jenis anggota') }}</v-col>
                            <v-col cols="6" md="3">{{ datatables.jenis_anggota }}</v-col>
                            <v-col cols="6" md="3">{{ $t('Pendidikan') }}</v-col>
                            <v-col cols="6" md="3">{{ datatables.pendidikan }}</v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col></v-row>
        </v-col>
    </v-row>
    
    <v-row><v-col cols="12" class="text-center">
        <a href="edit-profile" style="text-decoration:none;"><v-btn elevation="2" class="info"><v-icon class="mr-2">fa-cog</v-icon> {{ $t('Edit profile') }}</v-btn></a>
    </v-col></v-row>

    </v-card-text>
</v-card></v-col></v-row>
</v-container></v-main><Footer /></v-app></template>
<script>
import VueBarcode from 'vue-barcode';
import Header from './layout/Header';
import Footer from './layout/Footer';

  export default {
    components: { Header, Footer, 'barcode': VueBarcode },
    data: () => ({
        ajaxProcess:false,
        datatables:null,
        nophoto:process.env.VUE_APP_NO_PHOTO,
    }),
    methods: {
        refreshTable() {
            var vue = this;
            var requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };
            
            vue.ajaxProcess = true;
            fetch(process.env.VUE_APP_WEB_API + 'api/profile', requestOptions)
            .then(async response => {
                vue.datatables = await response.json();
                vue.ajaxProcess = false;
            })
            .catch(() => {
                window.location.replace("/logout");
            });
        }
    },
    mounted() {
        this.refreshTable(); 
    }
  }
</script>
